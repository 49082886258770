<template>
    <div class="w-[90%] lg:w-96">
        <div v-if="!submitted">
            <h1 class="text-3xl font-bold mb-2">{{ $t('Tso.signIn.title') }}</h1>
            <h2 class="mb-8 text-xs text-gray-400">
                {{ $t('Tso.signIn.dontHaveAccount') }}
                <NuxtLinkLocale to="register" class="text-black">
                    {{ $t('Tso.signIn.register') }}
                </NuxtLinkLocale>
            </h2>
            <FormKit id="form-sign-in" type="form" :actions="false" :submit-label="$t('Tso.signIn.login')" @submit="handleSignIn">
                <FormKit type="text" name="email" :label="$t('Tso.signIn.email')" validation="required|email" />
                <FormKit type="password" name="password" :label="$t('Tso.signIn.password')" validation="required|length:6" />
                <FormKit type="submit" :label="$t('Tso.signIn.login')" />
            </FormKit>
        </div>
        <div v-else class="flex justify-center w-full">
            <LazyCheckmark />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { reset } from '@formkit/core'
    import { useUser } from '#imports'

    interface FormData {
        email: string
        password: string
    }

    const { signIn, submitted } = useUser()

    async function handleSignIn({ email, password }: FormData) {
        reset('form-sign-in')
        await signIn(email, password)
    }
</script>
